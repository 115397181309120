"use client";

import Typography from "@mui/material/Typography";

export function ViewBoxAppBarTitle({ children }: React.PropsWithChildren) {
  return (
    <Typography
      variant="h6"
      textOverflow={"ellipsis"}
      overflow={"hidden"}
      whiteSpace={"nowrap"}
    >
      {children}
    </Typography>
  );
}
