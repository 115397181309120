"use client";

import type { AppBarProps } from "@mui/material/AppBar";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import { cloneElement } from "react";

interface ElevationScrollProps {
  readonly children: React.JSX.Element;
}

export function ElevationScroll({ children }: ElevationScrollProps) {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return cloneElement<AppBarProps>(children, {
    elevation: trigger ? 4 : 0,
  });
}
