"use client";

import Container from "@mui/material/Container";
import Skeleton from "@mui/material/Skeleton";
import dynamic from "next/dynamic";
import { memo } from "react";
import ReactDOM from "react-dom";
import { FormattedMessage } from "react-intl";

import { ViewBoxAppBar } from "@/widgets/app-bar/ui/app-bar";
import { ViewBoxAppBarTitle } from "@/widgets/app-bar/ui/app-bar-title";

const TrendingMovies = dynamic(
  () =>
    import("@/features/movie/trending-movies/ui/trending-movies").then(
      ({ TrendingMovies }) => ({
        default: TrendingMovies,
      }),
    ),
  {
    loading: () => <Skeleton height={452} />,
  },
);

const TrendingTVSeries = dynamic(
  () =>
    import("@/features/tv/trending-tv-series/ui/trending-tv-series").then(
      ({ TrendingTVSeries }) => ({
        default: TrendingTVSeries,
      }),
    ),
  {
    loading: () => <Skeleton height={452} />,
  },
);

const PopularMovies = dynamic(
  () =>
    import("@/features/movie/popular-movies/ui/popular-movies").then(
      ({ PopularMovies }) => ({
        default: PopularMovies,
      }),
    ),
  {
    loading: () => <Skeleton height={452} />,
  },
);

const PopularTVSeries = dynamic(
  () =>
    import("@/features/tv/popular-tv-series/ui/popular-tv-series").then(
      ({ PopularTVSeries }) => ({
        default: PopularTVSeries,
      }),
    ),
  {
    loading: () => <Skeleton height={452} />,
  },
);

const Movies = memo(function Movies() {
  return (
    <>
      <TrendingMovies timeWindow="day" />
      <TrendingMovies timeWindow="week" />
      <PopularMovies />
    </>
  );
});

const TVSeries = memo(function TVSeries() {
  return (
    <>
      <TrendingTVSeries timeWindow="day" />
      <TrendingTVSeries timeWindow="week" />
      <PopularTVSeries />
    </>
  );
});

const Featured = dynamic(
  () =>
    import("@/widgets/featured/ui/featured").then(({ Featured }) => ({
      default: Featured,
    })),
  {},
);

function PreloadResources(): React.ReactNode {
  ReactDOM.preconnect("https://apitmdb.viewbox.fun");
  ReactDOM.preconnect("https://imagetmdb.viewbox.fun", { crossOrigin: "" });

  return;
}

export function MainPage() {
  return (
    <>
      <PreloadResources />
      <ViewBoxAppBar
        title={
          <ViewBoxAppBarTitle>
            <FormattedMessage id="main" />
          </ViewBoxAppBarTitle>
        }
      />

      <Featured />
      <Container>
        <Movies />
        <TVSeries />
      </Container>
    </>
  );
}
