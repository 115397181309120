"use client";

import { createContext } from "react";

import { MainPagePreloadData } from "../types/main-page-preload-data";

export const MainPagePreloadContext = createContext<MainPagePreloadData>({
  tmdbPopularMovies: null,
  tmdbTrendingMovies: {
    day: null,
    week: null,
  },
  tmdbPopularTVSeries: null,
  tmdbTrendingTVSeries: {
    day: null,
    week: null,
  },
});
