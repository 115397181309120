"use client";

import type { MainPagePreloadData } from "../types/main-page-preload-data";
import { MainPagePreloadContext } from "./main-page-preload-context";

export const MainPagePreloadProvider = ({
  value,
  children,
}: React.PropsWithChildren<{ value: MainPagePreloadData }>) => {
  return (
    <MainPagePreloadContext.Provider value={value}>
      {children}
    </MainPagePreloadContext.Provider>
  );
};
